import React, { useState, useEffect, useContext } from 'react';
import { useTable, usePagination,useSortBy } from 'react-table';
import { Table, Pagination } from 'react-bootstrap';
import { getAllStoredTransactions } from '../ContractAction/TrendswapAction';
import Context from '../Context/Context';
let cachedAnalyticsLHSwapsData = [];

const LiquidityTable = () => {

    const [data, setData] = useState(cachedAnalyticsLHSwapsData);
    const [pageSize, setPageSize] = useState(5);
    const {wallet, connecting , connect, disconnect} = useContext(Context);

    const formatValue = (value) => {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            console.error("Invalid input: Not a number");
            return "$0.00";
        }
        const absValue = Math.abs(parseFloat(value));
        let formattedValue = absValue;
        if (absValue >= 1e12) {
            formattedValue = (absValue / 1e12).toFixed(2) + "T";
        } else if (absValue >= 1e9) {
            formattedValue = (absValue / 1e9).toFixed(2) + "B";
        } else if (absValue >= 1e6) {
            formattedValue = (absValue / 1e6).toFixed(2) + "M";
        } else if (absValue >= 1e3) {
            formattedValue = (absValue / 1e3).toFixed(2) + "K";
        } else {
            formattedValue = absValue.toFixed(2);
        }
        return `$${formattedValue}`;
    };

    const formatTimeDifference = (value) => {
        const dateValue = new Date(value);
        if (isNaN(dateValue.getTime())) {
            console.error("Invalid input: Not a valid date");
            return "- Invalid date";
        }
        const differenceInSeconds = Math.floor((new Date() - dateValue) / 1000);
        const intervals = [
            { label: 'day', duration: 86400 },
            { label: 'hr', duration: 3600 },
            { label: 'min', duration: 60 },
            { label: 'sec', duration: 1 }
        ];
        for (const interval of intervals) {
            if (differenceInSeconds >= interval.duration) {
                const count = Math.floor(differenceInSeconds / interval.duration);
                // return `- ${count} ${interval.label}${count > 1 ? 's' : ''}`;
                return count === 1 
                ? `${count} ${interval.label} ago` 
                : `${count} ${interval.label}s ago`;
            }
        }
        return '- Just now';
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="" style={{ paddingLeft: "20px", textAlign: "left", fontWeight: '500', minWidth: '100px' }}>
                        Tx Hash
                    </div>
                ),
                accessor: 'txHash',
                Cell: ({ value }) => <div style={{ textAlign: 'left', color: '#1d64fa', paddingLeft: "20px", minWidth: 'content-width' }}> {value && value.length > 32 ? `${value.substring(0, 6)}...${value.substring(value.length - 4)}` : value}</div>
            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '100px' }}>
                        In Amount
                    </div>
                ),
                accessor: 'amounttokenA', 
                sortType: (rowA, rowB, id) => {
                    const valueA = parseFloat(rowA.values[id]);
                    const valueB = parseFloat(rowB.values[id]);
                    return valueA > valueB ? 1 : -1;
                },
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>{`${Number(value.split(" ")[0]).toFixed(2)} ${value.split(" ")[1]}`}</div>,
            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '120px' }}>
                        Token Symbol
                    </div>
                ),
                accessor: 'symbolIn',
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '100px' }}>
                        In Value $
                    </div>
                ),
                accessor: 'amountAUSD',
                sortType: (rowA, rowB, id) => {
                    const valueA = parseFloat(rowA.values[id]);
                    const valueB = parseFloat(rowB.values[id]);
                    return valueA > valueB ? 1 : -1;
                },
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>{formatValue(value)}</div>,
            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '100px' }}>
                        Out Amount
                    </div>
                ),
                accessor: 'amounttokenB',
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>{`${Number(value.split(" ")[0]).toFixed(2)} ${value.split(" ")[1]}`}</div>,
            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '100px' }}>
                        Out Symbol
                    </div>
                ),
                accessor: 'symbolOut',
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '100px' }}>
                        Out Value $
                    </div>
                ),
                accessor: 'amountBUSDout',
                sortType: (rowA, rowB, id) => {
                    const valueA = parseFloat(rowA.values[id]);
                    const valueB = parseFloat(rowB.values[id]);
                    return valueA > valueB ? 1 : -1;
                },
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>${Number(value).toFixed(2) ? `-${Number(value).toFixed(4)}` : ''}</div>,

            },
            {
                Header: () => (
                    <div  className="" style={{ textAlign: 'center', fontWeight: '500', minWidth: '100px' }}>
                        Time
                    </div>
                ),
                accessor: 'createdAt',
                Cell: ({ value }) => <div style={{ textAlign: "center" }}>{formatTimeDifference(value)}</div>,
            },
        ],
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page,
        pageCount, state: { pageIndex }, nextPage, previousPage, setPageSize: setTablePageSize,
    } = useTable({ columns, data: data, initialState: { pageIndex: 0, pageSize } },useSortBy,
     usePagination);

    useEffect(() => {
        const fetchAllStoredTransactions = async () => {
            try {
                const storedTransactions1 = await getAllStoredTransactions();
                cachedAnalyticsLHSwapsData = storedTransactions1;

                console.log("storedTransactions:", storedTransactions1);
                if (storedTransactions1 && storedTransactions1.length > 0) {
                    const sortedTransactions = storedTransactions1?.map(transaction => {
                        const { amounttokenA, amounttokenB } = transaction;
                        console.log("amounttokenA:", amounttokenA);
                        console.log("amounttokenB:", amounttokenB);
                        const amountA = amounttokenA.match(/\d+\.\d+/)[0];
                        const amountB = amounttokenB.match(/\d+\.\d+/)[0];
                        console.log("amountA:", amountA);
                        console.log("amountB:", amountB);
                        console.log("transaction",...transaction);
                        return {
                            ...transaction,
                            diffInSeconds: Math.floor((new Date() - new Date(transaction.createdAt)) / 1000),
                            amounttokenA: parseFloat(amountA),
                            amounttokenB: parseFloat(amountB),
                            amountAUSD: parseFloat(transaction?.amountAUSD),
                            amountBUSDout: parseFloat(transaction?.amountBUSDout),
                        };
                    }).sort((a, b) => a.diffInSeconds - b.diffInSeconds);
                    try {
                        setData(storedTransactions1);
                        console.log("setTokenData_allstoredtxs:", data);
                    } catch (error) {
                        console.error("Error setting data:", error);
                    }
                } else {
                    console.log("No stored transactions found.");
                }
            } catch (error) {
                console.error("Error fetching stored transactions:", error);
            }
        };
        fetchAllStoredTransactions();
    }, []);

    useEffect(() => {
        try {
            setTablePageSize(pageSize);
        } catch (error) {
            console.error("Error setting table page size:", error);
        }
    }, [setTablePageSize, pageSize]);

    const totalRows = data.length;
    const startRecordIndex = pageIndex * pageSize + 1;
    const endRecordIndex = Math.min((pageIndex + 1) * pageSize, totalRows);
console.log("datagu",data);
    return (
        <div className='container mt-2 px-0'>
            <div className='table-responsive' style={{ borderRadius: '15px' }}>
                <Table {...getTableProps()} className='table '>
                    <thead className='' style={{ color: 'rgba(155, 151, 151, 1)' }}>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`text-center ${column.isSorted ? 'sorted-column' : ''}`} key={column.id}>
                                         <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="asc" className="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                                                        <line x1="12" y1="19" x2="12" y2="5"></line>
                                                        <polyline points="5 12 12 5 19 12"></polyline>
                                                    </svg>) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" direction="desc" className="styled__HeaderArrow-sc-eb013908-17 ldxGag">
                                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                                        <polyline points="19 12 12 19 5 12"></polyline>
                                                    </svg>
                                                )
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                   {wallet &&  <tbody {...getTableBodyProps()} style={{ background: 'linear-gradient(180deg, #032E50 0%, #101525 100%), linear-gradient(180deg, rgba(217, 217, 217, 0.075) 0%, rgba(217, 217, 217, 0.075) 100%)' }}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className='glassmorphism text-center table-row' style={{ color: "white" }} key={row.id}>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>}
                </Table>
            </div>
            {data.length > 0 && <div className='d-flex justify-content-end align-items-center gap-3'>
                <div className="custom-dropdown d-flex align-items-center justify-content-center">
                    Records per page:
                    <div className="dropdown-toggle" onClick={() => document.getElementById("dropdown-menu").classList.toggle("show")}>
                        {pageSize}
                    </div>
                    <div id="dropdown-menu" className="dropdown-menu">
                        <div className="dropdown-item" onClick={() => { setPageSize(3); document.getElementById("dropdown-menu").classList.remove("show"); }}>3</div>
                        <div className="dropdown-item" onClick={() => { setPageSize(5); document.getElementById("dropdown-menu").classList.remove("show"); }}>5</div>
                        <div className="dropdown-item" onClick={() => { setPageSize(10); document.getElementById("dropdown-menu").classList.remove("show"); }}>10</div>
                    </div>
                </div>
                <div className='pageCount'>
                    {startRecordIndex}-{endRecordIndex} of {totalRows}
                </div>
                <Pagination style={{ marginBottom: "0px" }}>
                    <Pagination.Prev onClick={previousPage} disabled={pageIndex === 0}>
                        <span aria-hidden="true">&laquo;</span>
                    </Pagination.Prev>
                    <Pagination.Next onClick={nextPage} disabled={pageIndex === pageCount - 1}>
                        <span aria-hidden="true">&raquo;</span>
                    </Pagination.Next>
                </Pagination>

            </div>
            }
        </div>
    );
};

export default LiquidityTable;