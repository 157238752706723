import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';
import Context from '../Context/Context';
import { getCurrentChainId, URLDOMAIN } from '../ContractAction/ContractDependency';

Chart.register(...registerables);

const BarChart = () => {
  const {
    timeFrame,
    setHoveredValue,
    hoveredDate,
    setHoveredDate,
    avgvalue,
    setAvgvalue,
    setIsBarHovered,
  } = useContext(Context);

  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [currentChainId, setCurrentChainId] = useState('');
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchCurrentChainId = async () => {
      try {
        const id = await getCurrentChainId();
        setCurrentChainId(id);
      } catch (error) {
        console.error('Error fetching current chain ID:', error);
      }
    };
    fetchCurrentChainId();
  }, []);

  useEffect(() => {
    if (!currentChainId) return;

    const apiMap = {
      D: 'getTradeVolTimeday',
      W: 'getTradeVolTimeweek',
      M: 'getTradeVolTimemonths',
      Y: 'getTradeVolTimeyear',
    };

    const endpoint = apiMap[timeFrame];
    if (!endpoint) {
      console.error('Invalid time frame:', timeFrame);
      return;
    }

    const apiUrl = `${URLDOMAIN}api/transction/${endpoint}?chainid=${currentChainId}`;
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        const formattedData = data.data.map((item) => ({
          x: item.timePeriod,
          y: item.totalAmountAUSD,
        }));
        setChartData(formattedData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchData();
  }, [timeFrame, currentChainId]);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.data.datasets[0].data = chartData;
      chartInstance.update();
    }
  }, [chartData]);

  useEffect(() => {
    if (chartRef.current && !chartInstance) {
      const ctx = chartRef.current.getContext('2d');
      const chart = new Chart(ctx, {
        type: 'bar',
        data: {
          datasets: [
            {
              data: chartData,
              backgroundColor: '#40fcfe',
              hoverBackgroundColor: 'rgba(64, 252, 254, 0.3)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onHover: (event, elements) => {
            setIsBarHovered(elements.length > 0);
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const date = new Date(context.parsed.x);
                  const formattedDate = format(date, 'PP');
                  const value = context.parsed.y;
                  setHoveredValue(value);
                  setHoveredDate(formattedDate);
                  return `Date: ${formattedDate}, Value: ${value}`;
                },
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: timeFrame === 'D' ? 'day' : timeFrame === 'W' ? 'week' : timeFrame === 'M' ? 'month' : 'year',
              },
              ticks: {
                callback: (value) => {
                  const date = new Date(value);
                  const formatMap = {
                    D: 'dd MMM',
                    W: "'Week' W",
                    M: 'MMM yy',
                    Y: 'yyyy',
                  };
                  return format(date, formatMap[timeFrame] || 'dd MMM');
                },
              },
            },
            y: {
              beginAtZero: true,
            },
          },
        },
      });
      setChartInstance(chart);
    }
  }, [chartRef, chartData, timeFrame]);

  useEffect(() => {
    if (chartData.length) {
      const total = chartData.reduce((sum, point) => sum + point.y, 0);
      const average = (total / chartData.length).toFixed(2);
      setAvgvalue(average);
    }
  }, [chartData]);

  return (
    <div style={{ width: '100%', height: '350px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default BarChart;
