import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import {getCurrentChainId} from '../ContractAction/ContractDependency';
import { use } from 'react';
const AreaChartGraph = () => {

  const [selection, setSelection] = useState('all');
  const [data, setData ] = useState([]);
  const [options, setOptions] = useState({
    colors: ['rgba(11, 134, 104,0.01)'],
    chart: {
      id: "tokenChart",
      background: 'rgba(0, 0, 0, 0)',
      toolbar: {
        show: false
      },
      zoom: {
        autoScaleYaxis: true
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5
      }
    },
    grid: {
      show: true,
      borderColor: 'rgba(235, 237, 240, 0.03)',
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      yAxisIndex: 1,
      type: 'datetime',
      labels: {
        show: true
      },
      axisBorder: {
        show: true,
        color: 'rgba(235, 237, 240, 0.03)'
      },
      tickPlacement: "on"
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$" + value.toFixed(0);
        }
      }
    },
    tooltip: {
      show: false
    },
    theme: {
      mode: "dark",
      monochrome: {
        enabled: false,
        color: '#fff',
        shadeTo: 'dark',
        shadeIntensity: 0
      },
    },
    legend: {
      show: false
    },
    stroke: {
      curve: 'smooth',
      width: 1,
      colors: ['#0B8668']
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        opacityFrom: 2,
        opacityTo: 3,
        stops: [20, 250]
      }
    }
  });

  const [series, setSeries] = useState([
    {
      name: 'Earnings',
      data: []
    }
  ]);

  // useEffect(() => {
  //   try {
  //     setSeries([
  //       {
  //         name: 'Earnings',
  //         data: data
  //       }
  //     ]);
  //   } catch (error) {
  //     console.error("Error setting series data:", error);
  //   }
  // }, [data]);
  useEffect(() => {

    if (data.length > 0) {

      setSeries([

        {

          name: "Earnings",

          data: data.map((item) => ({ x: item.timePeriod, y: item.totalAmountAUSD })),

        },

      ]);

    }

  }, [data]);

  const updateData =async  (timeline) => {
    console.log("timeline",timeline);
    const cainId = await getCurrentChainId();
    const address = localStorage.getItem('connectedAccount');
    setSelection(timeline);
    switch (timeline) {
      case 'one_day':
        fetch(`https://trenddx.io/api/transction/twentyFourHourDataLiquidity?chainid=${cainId}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              setData(data?.data);
              const dates = data?.data.map((item) => new Date(item.timePeriod).getTime());
              console.log("dates",dates);
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              console.log("First Date:", firstDate);
              console.log("Last Date:", lastDate);
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'one_week':
        fetch(`https://trenddx.io/api/transction/oneweekliquiditydata?chainid=${cainId}`)
          .then((response) => response.json())
          .then((data) => {
            console.log("data",data?.data);
            if (data.status === true) {
              setData(data?.data);
              const dates = data?.data.map((item) => new Date(item.timePeriod).getTime());
              console.log("dates",dates);
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              console.log("First Date:", firstDate);
              console.log("Last Date:", lastDate);
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'one_month':
        fetch(`https://trenddx.io/api/transction/getgraphliquidityonemonths?chainid=${cainId}`)
          .then((response) => response.json())
          .then((data) => {
            console.log("data",data?.data);
            if (data.status === true) {
              setData(data?.data);
              const dates = data?.data.map((item) => new Date(item.timePeriod).getTime());
              console.log("dates",dates);
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              console.log("First Date:", firstDate);
              console.log("Last Date:", lastDate);
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'six_month':
        fetch(`https://trenddx.io/api/transction/sixmonthsliquiditydata?chainid=${cainId}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              setData(data?.data);
              const dates = data?.data.map((item) => new Date(item.timePeriod).getTime());
              console.log("dates",dates);
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              console.log("First Date:", firstDate);
              console.log("Last Date:", lastDate);
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'all':
        fetch(`https://trenddx.io/api/transction/oneYeardataliquidity?chainid=${cainId}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === true) {
              setData(data?.data);
              const dates = data?.data.map((item) => new Date(item.timePeriod).getTime());
              console.log("dates",dates);
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              console.log("First Date:", firstDate);
              console.log("Last Date:", lastDate);
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      default:
    }
  }
  useEffect(() => {
    updateData(selection);
  },[])
console.log("hjgft",data);
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
          <div className="chart-btn-group">
  <button
    onClick={() => updateData('one_day')}
    style={{
      width: 'fit-content',
      fontSize: '12px',
      borderRadius: '50%',
      minWidth: '36px',
      backgroundColor: 'rgba(158, 169, 174, 0.24)',
      color: 'rgb(195, 197, 203)',
      padding: '7.44px',
      fontWeight: '500',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '1',
      margin: '0 4px', // Add horizontal gap
    }}
    className={`chart-btn ${selection === 'one_day' ? 'active' : ''}`}
  >
    24H
  </button>
  <button
    onClick={() => updateData('one_week')}
    style={{
      width: 'fit-content',
      fontSize: '12px',
      borderRadius: '50%',
      minWidth: '36px',
      backgroundColor: 'rgba(158, 169, 174, 0.24)',
      color: 'rgb(195, 197, 203)',
      padding: '7.44px',
      fontWeight: '500',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '1',
      margin: '0 4px', // Add horizontal gap
    }}
    className={`chart-btn ${selection === 'one_week' ? 'active' : ''}`}
  >
    7D
  </button>
  <button
    onClick={() => updateData('one_month')}
    style={{
      width: 'fit-content',
      fontSize: '12px',
      borderRadius: '50%',
      minWidth: '36px',
      backgroundColor: 'rgba(158, 169, 174, 0.24)',
      color: 'rgb(195, 197, 203)',
      padding: '7.44px',
      fontWeight: '500',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '1',
      margin: '0 4px', // Add horizontal gap
    }}
    className={`chart-btn ${selection === 'one_month' ? 'active' : ''}`}
  >
    1M
  </button>
  <button
    onClick={() => updateData('six_month')}
    style={{
      width: 'fit-content',
      fontSize: '12px',
      borderRadius: '50%',
      minWidth: '36px',
      backgroundColor: 'rgba(158, 169, 174, 0.24)',
      color: 'rgb(195, 197, 203)',
      padding: '7.44px',
      fontWeight: '500',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '1',
      margin: '0 4px', // Add horizontal gap
    }}
    className={`chart-btn ${selection === 'six_month' ? 'active' : ''}`}
  >
    6M
  </button>
  <button
    onClick={() => updateData('all')}
    style={{
      width: 'fit-content',
      fontSize: '12px',
      borderRadius: '50%',
      minWidth: '36px',
      backgroundColor: 'rgba(158, 169, 174, 0.24)',
      color: 'rgb(195, 197, 203)',
      padding: '7.44px',
      fontWeight: '500',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'nowrap',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '1',
      margin: '0 4px', // Add horizontal gap
    }}
    className={`chart-btn ${selection === 'all' ? 'active' : ''}`}
  >
    All
  </button>
</div>

          </div>
        </div>
      </div>
      <div id="tokenChart">
        <ReactApexChart options={options} series={series} type="area" height={200} />
      </div>
    </>
  );

}

export default AreaChartGraph;