import React, { useState , useEffect} from 'react';
import group from '../Assets/images2/Group.png';
import group1 from '../Assets/images2/Group (1).png';
import AllCryptosTable from './AllCryptosTable';
import NewListingTable from './NewListingTable';
import FavouritesTable from './FavouritesTable';
import { getTokenLiveInfoDetails } from '../../src/ContractAction/TrendswapAction';
let cachedTokenTableData = [];

const TotalTokens = ({searchQuery}) => {

  const [activeTab, setActiveTab] = useState('allCryptos');
  const [tokenData, setTokenData] = useState(cachedTokenTableData);
 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getTabStyle = (tabName) => ({
    color: activeTab === tabName ? 'rgb(64, 252, 254)' : '#9b9797',
  });

  const data = [
    { ticker: 'USDC.e', price: '$2,969.244', change: '+1.34%', image: group },
    { ticker: 'WETH', price: '$40,000.00', change: '-0.5%', image: group1 },
    { ticker: 'MATIC', price: '$2,969.244', change: '+1.34%', image: group },
    { ticker: 'BTC', price: '$40,000.00', change: '-0.5%', image: group1 },
    { ticker: 'DAi', price: '$2,969.244', change: '+1.34%', image: group },
  ];
  const formatValue = (value) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
        console.error("Invalid input: Not a number");
        return "$0.00";
    }
    const absValue = Math.abs(value);
    if (absValue >= 1e12) {
        return `$${(value / 1e12).toFixed(2)}T`;
    } else if (absValue >= 1e9) {
        return `$${(value / 1e9).toFixed(2)}B`;
    } else if (absValue >= 1e6) {
        return `$${(value / 1e6).toFixed(2)}M`;
    } else if (absValue >= 1e3) {
        return `$${(value / 1e3).toFixed(2)}K`;
    } else {
        return `$${value}`;
    }
};
const calculate1HourDifference = (coin) => {
  try {
      const sparkline = coin.sparkline;
      if (!Array.isArray(sparkline) || sparkline.length < 2) {
          console.error("Invalid sparkline data");
      }
      const lastPrice = parseFloat(sparkline[sparkline.length - 2]);
      const secondLastPrice = parseFloat(coin.price);
      if (isNaN(lastPrice) || isNaN(secondLastPrice)) {
          console.error("Invalid price data");
      }
      const absoluteDifference = lastPrice - secondLastPrice;
      const averagePrice = (lastPrice + secondLastPrice) / 2;
      if (averagePrice === 0) {
          console.error("Average price is zero, cannot calculate percentage difference");
      }
      const percentageDifference = ((absoluteDifference / averagePrice) * 100).toFixed(2);
      console.log("calculate1HourDifference:", percentageDifference);
      return percentageDifference;
  } catch (error) {
      console.error("Error calculating 1 hour difference:", error.message);
      return null;
  }
};
  useEffect(() => {
    const fetchData = async () => {
        try {
            const TokenLiveInfoDetails = await getTokenLiveInfoDetails();
            const updatedTokenData = TokenLiveInfoDetails.data.coins.map(coin => ({
                ...coin,
                hourChange: calculate1HourDifference(coin),
            }));
            try {
                cachedTokenTableData =  updatedTokenData.slice(0, 4);
                console.log("cachedTokenTableData123456:", updatedTokenData);
            } catch (error) {
                console.error("Error caching token table data:", error);
            }
            try {
              const limitedTokenData = updatedTokenData.slice(0, 4);
              setTokenData(limitedTokenData);
              console.log("tokenData:", limitedTokenData);
                console.log("tokenData:", updatedTokenData);
            } catch (error) {
                console.error("Error setting token data:", error);
            }
        } catch (error) {
            console.error("Error fetching token live info details:", error);
        }
    };
    fetchData();
}, []);

  return (
    <>
      <div className="container tokens mt-2 d-flex justify-content-evenly flex-column" style={{ height: '120px', borderRadius: '15px', background: 'linear-gradient(180deg, #032e50, #101525), linear-gradient(180deg, rgba(217, 217, 217, 0.08), rgba(217, 217, 217, 0.08))' }}>
        <p className="m-0 ms-3" style={{ color: 'rgba(155, 151, 151, 1)', fontWeight: '800' }}>24h MOST VOLUME</p>
        <div className="topMoversContent">
          <div>
            {tokenData?.map((item, index) => (
              <div key={item?.symbol} className="topMoverItem ms-3">
                <div className="jss66 currencyLogo">
                  <img className="cryptocurrency-colorusdc-icon group-icon" alt="" src={item.iconUrl} />
                </div>
                <div className="jss67">
                  <small className="ticker">{item.symbol}</small>
                  <div className="d-flex align-items-center justify-content-center">
                    <small className="ticker_value">{item.price}</small>
                    <div
  className="topMoverText"
  style={{
    color: item.hourChange < 0 ? 'rgba(214, 95, 82, 1)' : 'green', // Red for negative, inherit for default
  }}
>
  <span>{item.hourChange}</span>
</div>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container d-flex align-items-center justify-content-between mt-3">
        <div className="d-flex px-0" style={{ gap: '0px 20px' }}>
          <p className={`m-0 top-tokens fw-bold ${activeTab === 'allCryptos' ? 'active1' : ''}`} style={getTabStyle('allCryptos')} onClick={() => handleTabClick('allCryptos')}>
            All Cryptos
          </p>
          <p className={`m-0 top-tokens fw-bold ${activeTab === 'favorites' ? 'active1' : ''}`} style={getTabStyle('favorites')} onClick={() => handleTabClick('favorites')}>
            Favorites
          </p>
          <p className={`m-0 top-tokens fw-bold ${activeTab === 'newListings' ? 'active1' : ''}`} style={getTabStyle('newListings')} onClick={() => handleTabClick('newListings')}>
            New Listings
          </p>
        </div>
      </div>
      {/* Conditionally render content based on the active tab */}
      {activeTab === 'allCryptos' && <AllCryptosTable searchQuery={searchQuery} />}
      {activeTab === 'favorites' && <FavouritesTable />}
      {activeTab === 'newListings' && <NewListingTable />}
    </>
  );

};

export default TotalTokens;